import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { findKey } from 'lodash-es';
import Image from 'next/image';
import { useState } from 'react';
import { TrustpilotRating } from '../../products/TrustpilotWidget';
import {
  ReviewSummaryT,
  SummaryCardProps,
  ReviewT,
  ProductReviewsProps,
} from '@/settings/types';
import ReviewCard from './ReviewCard';
import TrustReviewSlider from '@/components/commons/TrustReviewSlider';
import { getProductReviews } from '@/lib/use-product-reviews';
import { logger } from '@/lib/logger';

dayjs.extend(relativeTime);
const DEFAULT_FALLBACK_REVIEW_STARS = 4.5;
const MAX_REVIEW_STARS = 5;

export const generateSummaryData = (summary: ReviewSummaryT | undefined) => {
  if (summary == null) {
    return {
      count: 0,
      stars: 0,
      countAbove3andAHalf: 0,
      starPercentages: {},
    };
  }
  // Fallback for undeterministic (or corrupted) data: DANGER - this hides root issue
  const numberOfStars = summary.stars ?? 4;
  const safeStars =
    numberOfStars > MAX_REVIEW_STARS
      ? DEFAULT_FALLBACK_REVIEW_STARS
      : Number(numberOfStars.toFixed(1));

  // If we have less than 20 reviews, the minimum stars will be 4.5
  const fakeStars =
    summary.count < 20
      ? Math.max(DEFAULT_FALLBACK_REVIEW_STARS, safeStars)
      : safeStars;

  return {
    count: summary.count,
    stars: fakeStars,
    countAbove3andAHalf: summary.countAbove3andAHalf,
    starPercentages: summary.starPercentages,
  };
};

const SummaryCard = (props: SummaryCardProps) => {
  const { summary, fallbackImageSrc, offerImage } = props;
  const [imageError, setImageError] = useState(false);

  return (
    <div className="flex h-full w-full items-center justify-center gap-6 rounded-lg border p-6 md:w-1/3 md:flex-col md:items-start md:justify-start md:gap-0 md:p-4">
      <div className="relative h-[112px] w-[112px] md:hidden">
        <Image
          src={imageError ? fallbackImageSrc : offerImage}
          onError={() => setImageError(true)}
          alt={'Offer'}
          className="h-full w-full object-contain"
          height={112}
          width={112}
        />
      </div>
      <div className="text-center">
        <div className="flex items-center gap-2">
          <h3 className="text-2xl font-bold">{summary.stars}</h3>
          <TrustpilotRating grade={summary.stars} className="h-4 xxs:h-5" />
        </div>
        <div className="flex w-full justify-center text-center text-sm md:w-auto md:justify-start md:text-left md:text-xs">
          {summary.count.toLocaleString()} customer{' '}
          {summary.count !== 1 ? 'reviews' : 'review'}
        </div>
      </div>
      <div className="mt-6 hidden w-full flex-col gap-4 md:flex">
        {/* Map out star rating bars ordered 5 - 1 */}
        {summary?.starPercentages &&
          Object.keys(summary.starPercentages)
            .sort((a, b) => Number(b) - Number(a))
            .map((star) => (
              <div className="flex items-center gap-2">
                <span className="whitespace-nowrap text-xs">{star} star</span>
                <div className=" relative h-2 w-full rounded-lg bg-gray-200">
                  <div
                    className="absolute left-0 top-0 h-full rounded-lg bg-black"
                    // eslint-disable-next-line react/forbid-dom-props
                    style={{ width: `${summary.starPercentages[star]}%` }}
                  ></div>
                </div>
                <span className="w-8 text-center text-xs">
                  {summary.starPercentages[star]}%
                </span>
              </div>
            ))}
      </div>
    </div>
  );
};

export default function ProductReviews(props: ProductReviewsProps) {
  const {
    product,
    type,
    summary,
    reviews,
    selectedImage,
    setReviewsInState,
    reviewsInState,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  if (summary.count === 0 || (summary.count < 3 && summary.stars < 4))
    return <TrustReviewSlider />;

  const handleReviewPagination = async ({ psku }: { psku: string }) => {
    if (!reviewsInState?.reviews.length || !summary.countAbove3andAHalf) return;
    setIsLoading(true);

    try {
      const paginatedReviews = await getProductReviews({
        psku,
        lastIndex: Math.min(
          reviewsInState.reviews.length - 1,
          summary.countAbove3andAHalf - 1,
        ),
        batchSize: 4,
      });

      if (paginatedReviews) {
        setReviewsInState({
          ...reviewsInState,
          reviews: [...reviewsInState.reviews, ...paginatedReviews.reviews],
        });
      }
    } catch (error) {
      logger.error({ error }, 'error paginating product reviews');
    } finally {
      setIsLoading(false);
    }
  };

  const fallbackImageSrc = Object.values(product.images ?? {})?.[0]?.[0];

  return (
    <div className="flex flex-col gap-5 bg-white" id="reviews-slider">
      <h2 className="flex items-center text-lg font-semibold md:text-xl">
        Customer Reviews & Ratings
      </h2>
      <div className="flex flex-col gap-2 md:flex-row">
        <SummaryCard
          summary={generateSummaryData(summary)}
          offerImage={
            type === 'single-variant-reviews'
              ? product.collectionImageUrl
              : selectedImage ?? product.collectionImageUrl
          }
          fallbackImageSrc={fallbackImageSrc ?? ''}
        />
        <div className="relative mt-5 flex w-full flex-col gap-5  md:mt-0 md:pl-5">
          {reviews.map((r: ReviewT) => (
            <ReviewCard
              key={r.id}
              review={r}
              type={type}
              fallbackImageSrc={fallbackImageSrc ?? ''}
              offer={{
                offerTitle: product.name,
                offerImage:
                  type === 'single-variant-reviews'
                    ? product.collectionImageUrl
                    : product.images?.[
                        findKey(
                          product.colors,
                          (o) => o.toLowerCase() === r.color,
                        ) ?? ''
                      ]?.[0] ?? product.collectionImageUrl,
              }}
            />
          ))}
          <div className="mt-5 flex w-full justify-start">
            {reviews.length < summary.countAbove3andAHalf && (
              <button
                className="rounded px-3 py-2 text-xs outline outline-1 hover:bg-black hover:text-white"
                onClick={() => handleReviewPagination({ psku: product.psku })}
              >
                {isLoading ? 'Loading...' : 'Load More Reviews'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
