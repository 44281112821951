import Image from 'next/future/image';
import cn from 'classnames';
import WhiteStar from '@/public/icons/collection/white_star.svg';
import BlackStar from '@/public/icons/collection/black_star.svg';
import HalfStar from '@/public/icons/collection/half_star.svg';
import { generateSummaryData } from '@/components/collections/reviews/ProductReviews';
import { ReviewSummaryT } from '@/settings/types';

const ReviewsStars = ({
  summary,
  className = '',
  offset = 80,
  starSize = 12,
  scrollBy = '',
  hideFive = false,
}: {
  summary: ReviewSummaryT;
  className?: string;
  offset?: number;
  starSize?: number;
  scrollBy?: string;
  hideFive?: boolean;
}) => {
  if (summary.stars < 4 && summary.count < 3) return null;

  const { stars, count } = generateSummaryData(summary);

  function createStarArray(rating: number) {
    const roundedRating = Math.round(rating * 2) / 2;
    const starArray = [];

    for (let i = 1; i <= 5; i += 1) {
      if (i <= roundedRating) {
        starArray.push(
          <Image
            src={BlackStar}
            width={starSize}
            height={starSize}
            alt={`Star ${i}`}
            priority
            key={`Star ${i}`}
          />,
        );
        // Rounding the rating to closest half
      } else if (i - 0.5 === roundedRating) {
        starArray.push(
          <Image
            src={HalfStar}
            width={starSize}
            height={starSize}
            alt={`Star ${i}`}
            priority
            key={`Star ${i}`}
          />,
        );
      } else {
        starArray.push(
          <Image
            src={WhiteStar}
            width={starSize}
            height={starSize}
            alt={`Star ${i}`}
            priority
            key={`Star ${i}`}
          />,
        );
      }
    }

    return starArray;
  }

  return (
    <button
      onClick={() => {
        if (scrollBy) {
          const el = document.getElementById(scrollBy);

          if (el) {
            window.scrollBy({
              top: el.offsetTop - offset,
              behavior: 'smooth',
            });
          }
        }
      }}
      className={cn('flex items-center gap-0.5', className, {
        'cursor-default': !scrollBy,
      })}
    >
      {createStarArray(stars)}
      <div className="ml-1 flex items-center">
        {!hideFive && (
          <div className="mr-2 flex">
            <span>{stars}</span>

            <span className="mt-[-1px] ml-[1px] hidden text-[9px] xxs:block">
              <span className="mr-[1px] inline-block">/</span>5
            </span>
          </div>
        )}
        <span
          className={cn({
            'text-[#0D6EFD] underline': scrollBy,
          })}
        >
          {count} review{count !== 1 && 's'}
        </span>
      </div>
    </button>
  );
};

export default ReviewsStars;
