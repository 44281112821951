import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import Image from 'next/future/image';
import { debounce } from 'lodash-es';
import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import cn from 'classnames';
import CheckIcon from '@/public/icons/collection/check.svg';
import { TrustpilotRating } from '@/components/products/TrustpilotWidget';
import { ReviewCardProps } from '@/settings/types';
import settings from '@/settings';

function ReviewCard(props: ReviewCardProps) {
  const { review, type, offer, fallbackImageSrc } = props;
  const [shouldReadMore, setShouldReadMore] = useState(false);
  const [showShouldReadLess, setShowShouldReadLess] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const storeTimeZone = REEBELO_TZ_NAME[settings.store];
  const [imageError, setImageError] = useState(false);

  const generateDisplayName = (name: string) => {
    const nameArr = name.split(' ').filter(Boolean);

    return nameArr.length > 1 ? `${nameArr[0]} ${nameArr[1][0]}.` : nameArr[0];
  };

  const displayName = generateDisplayName(review.customerName);

  useEffect(
    debounce(() => {
      if (contentRef.current) {
        if (
          contentRef.current?.scrollHeight > contentRef.current?.clientHeight ||
          contentRef.current?.scrollHeight > 280
        )
          setShouldReadMore(true);
      }
    }, 300),
    [contentRef.current],
  );

  const toggleShowFullText = () => {
    setShouldReadMore(!shouldReadMore);
    setShowShouldReadLess(!showShouldReadLess);
  };

  return (
    <div className="flex rounded-lg border bg-white py-1">
      <div className="flex w-full flex-col justify-between p-5 text-xs">
        {/* Header  */}
        <div
          className={cn(
            'flex w-full flex-col items-start justify-between gap-4 ',
            { 'md:flex-row md:items-center': type !== 'vendor-reviews' },
          )}
        >
          <TrustpilotRating
            grade={review.stars}
            className="-ml-1 h-4 xxs:h-5"
          />
          <div className="flex w-full items-center justify-between gap-2">
            <h3 className="font-bold">{review.title}</h3>
            {review.createdAt && (
              <div className="ml-3 flex shrink-0 items-center">
                <span>
                  {dayjs(review.createdAt)
                    .tz()
                    .isBefore(dayjs().subtract(2, 'week'))
                    ? dayjs
                        .tz(review.createdAt, storeTimeZone)
                        .format('MMM. DD, YYYY')
                    : dayjs.tz(review.createdAt, storeTimeZone).fromNow()}
                </span>
              </div>
            )}
          </div>
        </div>
        {/* Body  */}
        <div
          className="mt-3 flex-1 gap-2 overflow-y-auto pb-3"
          ref={contentRef}
        >
          <p
            className="mt-1.5 font-light leading-normal"
            // eslint-disable-next-line react/forbid-dom-props
            style={
              shouldReadMore
                ? {
                    maxHeight: 'calc(1.5em * 7)', // 1.5em is approximately the height of one line of text
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 7, // Limit to 7 lines
                    WebkitBoxOrient: 'vertical',
                  }
                : {}
            }
          >
            {review.text}
          </p>
          {shouldReadMore && (
            <button
              type="button"
              className="font-light underline"
              onClick={() => toggleShowFullText()}
            >
              Read More
            </button>
          )}
          {showShouldReadLess && (
            <button
              type="button"
              className="font-light underline"
              onClick={() => toggleShowFullText()}
            >
              Read Less
            </button>
          )}
        </div>
        <div className="mt-1 flex gap-2">
          <h4 className="shrink-0 self-end capitalize">
            {displayName || 'Anonymous'}
          </h4>
          <div className="flex items-start gap-1">
            <Image
              priority
              alt="Check Icon"
              src={CheckIcon}
              height={12}
              width={12}
            />
            <span className="text-xs font-light">
              Purchased on {dayjs(review.purchaseDate).format('MMM. DD, YYYY')}
            </span>
          </div>
        </div>
        {/* Footer */}
        {['multi-variant-reviews', 'vendor-reviews'].includes(type) && (
          <div className="mt-3 flex items-center border-t pt-4">
            {offer.offerImage != null && fallbackImageSrc != null && (
              <div className="mr-3 flex items-center justify-center">
                <div className="relative hidden h-[64px] w-[64px] md:block">
                  <Image
                    src={imageError ? fallbackImageSrc : offer.offerImage}
                    onError={() => setImageError(true)}
                    alt={'Offer'}
                    className="h-full w-full object-contain"
                    height={64}
                    width={64}
                  />
                </div>
                <div className="relative h-[80px] w-[80px] md:hidden">
                  <Image
                    src={imageError ? fallbackImageSrc : offer.offerImage}
                    onError={() => setImageError(true)}
                    alt={'Offer'}
                    className="h-full w-full object-contain"
                    height={80}
                    width={80}
                  />
                </div>
              </div>
            )}
            <div className="max-w-[75%] text-xs ">
              <h3 className="truncate font-bold capitalize">
                {offer.offerTitle}{' '}
                {review.networkProvider && `- ${review.networkProvider}`}
              </h3>
              <h4 className="truncate capitalize">
                {[review.storage, review.color, review.condition]
                  .filter(Boolean)
                  .join(' | ')}
              </h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewCard;
