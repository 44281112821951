import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import useSWRImmutable from 'swr/immutable';
import Link from 'next/link';
import type { BusinessDetails, Review } from '@/api/trustpilot/reviews';
import { TrustpilotRating, LogoFull } from '../products/TrustpilotWidget';
import TrustpilotReviewCard from '../collections/reviews/TrustpilotReviewCard';

dayjs.extend(relativeTime);

type Response = { business: BusinessDetails; reviews: Review[] };

const URL = 'https://www.trustpilot.com/review/reebelo.com';

export default function TrustReviewSlider() {
  const { data: reviews } = useSWRImmutable<Response>(
    '/api/trustpilot/reviews',
  );

  if (reviews == null) return null;

  return (
    <div className="flex flex-col gap-5 bg-white">
      <h2 className="flex shrink-0 items-center text-lg font-semibold md:text-xl ">
        Customer Reviews & Ratings
      </h2>
      <div className="flex flex-col gap-6 md:flex-row">
        {/* Summary Card */}
        <div className="flex h-full min-w-[250px] flex-col items-center justify-center gap-4 rounded-lg border p-6 md:w-1/4">
          <span className="text-xl font-bold">Excellent</span>
          <TrustpilotRating
            grade={reviews.business.score.trustScore}
            className="h-6"
          />
          <span className="text-sm">
            Based on{' '}
            <a href={URL} target="_blank" className="underline">
              {reviews.business.numberOfReviews.total.toLocaleString()} Reviews
            </a>
          </span>
          <LogoFull />
        </div>
        <div className="flex w-full flex-col gap-6">
          {reviews.reviews.slice(0, 6).map((r) => (
            <TrustpilotReviewCard review={r} key={`review_${r.id}`} />
          ))}
          <div className="flex w-full justify-start">
            <Link href={`/reviews`} passHref>
              <a className="rounded px-3 py-2 text-xs outline outline-1 hover:bg-black hover:text-white">
                See all reviews
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
