import useSWR from 'swr';
import axios from 'axios';
import { REEBELO_DOMAINS } from '@lambda/reebelo';
import { useEffect, useState } from 'react';
import { ProductReviewsT } from '@/settings/types';
import { logger } from './logger';
import settings from '@/settings';

// Create a custom hook to fetch reviews data
function useProductReviews(psku: string) {
  const {
    data: reviews = {
      summary: {
        count: 0,
        stars: 0,
        minRating: 3.5,
        maxRating: 5,
        countAbove3andAHalf: 0,
        starPercentages: {
          '1': 0,
          '2': 0,
          '3': 0,
          '4': 0,
          '5': 0,
        },
      },
      reviews: [],
    },
    isValidating,
  } = useSWR<ProductReviewsT>(
    `/api/collections/reviews?fileName=reviews_v2/${psku}.json`,
  );
  const [reviewsInState, setReviewsInState] = useState<ProductReviewsT | null>(
    null,
  );

  useEffect(() => {
    setReviewsInState(reviews);
  }, [isValidating]);

  return { reviews, reviewsInState, setReviewsInState };
}

export default useProductReviews;

export const generateReviewDisplayName = (name: string) => {
  const nameArr = name.split(' ').filter(Boolean);

  return nameArr.length > 1 ? `${nameArr[0]} ${nameArr[1][0]}.` : nameArr[0];
};

export async function getProductReviews({
  psku,
  lastIndex,
  batchSize,
}: {
  psku: string;
  lastIndex?: number;
  batchSize?: number;
}) {
  const domain =
    process.env.STAGE === 'dev'
      ? 'localhost:3000'
      : REEBELO_DOMAINS[settings.store];

  const protocol = process.env.STAGE === 'dev' ? 'http' : 'https';
  let url = `${protocol}://${domain}/api/collections/reviews?fileName=reviews_v2/${psku}.json`;

  if (lastIndex) url += `&lastIndex=${lastIndex}`;
  if (batchSize) url += `&batchSize=${batchSize}`;

  try {
    const { data } = await axios.get<ProductReviewsT>(url);

    return data;
  } catch (error) {
    logger.error(
      { error, domain, protocol, url },
      'error fetching product reviews',
    );

    return null;
  }
}
