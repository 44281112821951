import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
import { generateReviewDisplayName } from '@/lib/use-product-reviews';
import { TrustpilotRating } from '@/components/products/TrustpilotWidget';
import type { Review } from '@/api/trustpilot/reviews';

dayjs.extend(relativeTime);

function TrustpilotReviewCard(props: { review: Review }) {
  const { review } = props;
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [shouldReadMore, setShouldReadMore] = useState(false);
  const [showShouldReadLess, setShowShouldReadLess] = useState(false);

  const displayName = review.consumer.displayName
    ? generateReviewDisplayName(review.consumer.displayName)
    : 'Anonymous';

  useEffect(
    debounce(() => {
      if (contentRef.current) {
        if (
          contentRef.current?.scrollHeight > contentRef.current?.clientHeight ||
          contentRef.current?.scrollHeight > 280
        )
          setShouldReadMore(true);
      }
    }, 300),
    [contentRef.current],
  );

  const toggleShowFullText = () => {
    setShouldReadMore(!shouldReadMore);
    setShowShouldReadLess(!showShouldReadLess);
  };

  return (
    <div className="flex h-full w-full flex-col justify-between rounded-lg border bg-white p-5 text-xs">
      {/* Header  */}
      <div className="flex w-full flex-col items-start justify-between gap-4">
        <TrustpilotRating grade={review.stars} className="-ml-1 h-4 xxs:h-5" />
        <div className="flex w-full flex-col-reverse gap-2 sm:flex-row sm:items-center sm:justify-between">
          <h3 className="font-bold">{review.title}</h3>
          {review.createdAt && (
            <div className="flex shrink-0 items-center sm:ml-3">
              <span>
                {dayjs(review.createdAt).isBefore(dayjs().subtract(2, 'week'))
                  ? dayjs(review.createdAt).format('MMM. DD, YYYY')
                  : dayjs(review.createdAt).fromNow()}
              </span>
            </div>
          )}
        </div>
      </div>
      {/* Body  */}
      <div className="mt-3 flex-1 gap-2 overflow-y-auto pb-3" ref={contentRef}>
        <p
          className="mt-1.5 font-light leading-normal"
          // eslint-disable-next-line react/forbid-dom-props
          style={
            shouldReadMore
              ? {
                  maxHeight: 'calc(1.5em * 7)', // 1.5em is approximately the height of one line of text
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 7, // Limit to 7 lines
                  WebkitBoxOrient: 'vertical',
                }
              : {}
          }
        >
          {review.text}
        </p>
        {shouldReadMore && (
          <button
            type="button"
            className="font-light underline"
            onClick={() => toggleShowFullText()}
          >
            Read More
          </button>
        )}
        {showShouldReadLess && (
          <button
            type="button"
            className="font-light underline"
            onClick={() => toggleShowFullText()}
          >
            Read Less
          </button>
        )}
      </div>
      <div className="mt-1 flex gap-2">
        <h4 className="shrink-0 self-end capitalize">
          {displayName || 'Anonymous'}
        </h4>
      </div>
    </div>
  );
}

export default TrustpilotReviewCard;
